import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FourOhFourPageStyles = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 190px);
  justify-content: center;

`;

const NotFoundPage = () => (
  <FourOhFourPageStyles>
      <h1>Page not found</h1>
      <p>
        This is not the page you're looking for
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>
        <br />
        <br />
        <Link to="/">Go to the homepage</Link>
      </p>
  </FourOhFourPageStyles>
);

export default NotFoundPage;
